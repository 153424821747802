const Video = ({ key, srcPoster, srcVideo }) => {
  return (
    <video
      autoPlay
      muted
      loop
      key={key}
      preload="true"
      poster={srcPoster}
      controls={false}
      playsInline>
      <source src={srcVideo} type="video/webm" />
    </video>
  )
}

export default Video
