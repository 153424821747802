import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import useSound from 'use-sound'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import styles from './Screen2.module.scss'
import sound from '../../assets/sounds/sound-screen-2.MP3'
import sound_aac from '../../assets/sounds/sound-screen-6.aac'
import { ReactComponent as Sound } from '../../assets/images/sound.svg'
import { ReactComponent as OrageBorder } from '../../assets/images/orange-border.svg'
import poster1 from '../../assets/video/page-2/Page_2_Headphone.gif'
import poster2 from '../../assets/video/page-2/Page_2_Coin.gif'
import poster3 from '../../assets/video/page-2/Page_2_Pill.gif'
import poster4 from '../../assets/video/page-2/Page_2_Key.gif'
import poster1_a from '../../assets/video/page-2/daltonizm/Page_2_a_Headphone.gif'
import poster2_a from '../../assets/video/page-2/daltonizm/Page_2_a_Coin.gif'
import poster3_a from '../../assets/video/page-2/daltonizm/Page_2_a_Pill.gif'
import poster4_a from '../../assets/video/page-2/daltonizm/Page_2_a_Key.gif'
import video1 from '../../assets/video/page-2/Page-2_Headphone.webm'
import video2 from '../../assets/video/page-2/Page-2_Coin.webm'
import video3 from '../../assets/video/page-2/Page-2_Pill.webm'
import video4 from '../../assets/video/page-2/Page-2_Key.webm'
import Visual from '../../components/Visual/Visual'
import useIsIphone from '../../utils/useIsIphone'
import Video from '../../components/Video/Video'

const Screen2 = ({ difficulty, setDifficulty, setKey }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [srcPoster, setSrcPoster] = useState({
    poster1: poster1,
    poster2: poster2,
    poster3: poster3,
    poster4: poster4,
  })
  const audioSources = [sound, sound_aac]

  const isMobile = useMediaQuery({ query: '(max-width: 1279px)' })
  const [play, { stop, pause }] = useSound(audioSources, {
    onplay: () => setIsPlaying(true),
    onend: () => setIsPlaying(false),
    volume: 0.7,
  })

  const isIphone = useIsIphone()

  useEffect(() => {
    difficulty.value === 'daltonizm'
      ? setSrcPoster({
          poster1: poster1_a,
          poster2: poster2_a,
          poster3: poster3_a,
          poster4: poster4_a,
        })
      : setSrcPoster({
          poster1: poster1,
          poster2: poster2,
          poster3: poster3,
          poster4: poster4,
        })
    difficulty.value === 'visual' ? play() : stop()
  }, [difficulty, play, stop])
  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])
  return (
    <div className="main-wrap main-wrap__dark">
      {isMobile ? (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={''}
          isModalStory={true}
          setKey={setKey}
        />
      ) : (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={'about'}
          isModalStory={true}
          setKey={setKey}
        />
      )}
      <div className="content">
        <div className="container">
          <div className={styles.wrap}>
            <div className={`dyslexia-text ${styles.text} text-position-2`}>
              <p>
                Сейчас я прячу зародыши любой мечты. <br />
                <span className="orange-border daltonizm-color">
                  Глубоко-глубоко. <OrageBorder />
                </span>{' '}
                <br className={styles.mb} />
                В&nbsp;карманы, на&nbsp;дно рюкзаков и&nbsp;сумок, между строчек в&nbsp;тетрадках.
                Прячь, говорила мама, а&nbsp;то&nbsp;украдут. Иногда я&nbsp;выношу свои&nbsp;мечты
                погулять на&nbsp;улицу, но&nbsp;снаружи опаснее.
              </p>

              {isIphone ? (
                <>
                  <div className={styles.img + ' ' + styles.img__left_1}>
                    <img src={srcPoster.poster1} alt="Очень важная история" />
                  </div>
                  <div className={styles.img + ' ' + styles.img__left_2}>
                    <img src={srcPoster.poster2} alt="Очень важная история" />
                  </div>
                  <div className={styles.img + ' ' + styles.img__right_1}>
                    <img src={srcPoster.poster3} alt="Очень важная история" />
                  </div>
                  <div className={styles.img + ' ' + styles.img__right_2}>
                    <img src={srcPoster.poster4} alt="Очень важная история" />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.img + ' ' + styles.img__left_1}>
                    <Video key={difficulty.value} srcPoster={srcPoster.poster1} srcVideo={video1} />
                  </div>
                  <div className={styles.img + ' ' + styles.img__left_2}>
                    <Video key={difficulty.value} srcPoster={srcPoster.poster2} srcVideo={video2} />
                  </div>
                  <div className={styles.img + ' ' + styles.img__right_1}>
                    <Video key={difficulty.value} srcPoster={srcPoster.poster3} srcVideo={video3} />
                  </div>
                  <div className={styles.img + ' ' + styles.img__right_2}>
                    <Video key={difficulty.value} srcPoster={srcPoster.poster4} srcVideo={video4} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {difficulty.value === 'visual' && (
        <Visual
          isPlaying={isPlaying}
          pause={pause}
          play={play}
          setIsPlaying={setIsPlaying}
          sound={<Sound />}
        />
      )}
      <Footer
        linkLeft={{ text: 'Назад', link: '/screen-1' }}
        linkRight={{ text: 'Дальше', link: '/screen-3' }}
        currentStep={2}
        maxStep={7}
      />
    </div>
  )
}

export default Screen2
