import { useEffect, useState } from 'react'

const useIsIphone = () => {
  const [isIphone, setIsIphone] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/iPhone/i.test(userAgent)) {
      setIsIphone(true)
    }
  }, [])

  return isIphone
}

export default useIsIphone
