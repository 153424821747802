import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import useSound from 'use-sound'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import styles from './Screen7.module.scss'
import video from '../../assets/video/Page-7.webm'
import videoD from '../../assets/video/Page-7a.webm'
import poster from '../../assets/video/Page_7.gif'
import posterD from '../../assets/video/Page_7_a.gif'
import sound from '../../assets/sounds/sound-screen-7.MP3'
import { ReactComponent as Sound } from '../../assets/images/sound.svg'
import Visual from '../../components/Visual/Visual'

const Screen7 = ({ difficulty, setDifficulty, setKey }) => {
  const [srcVideo, setSrcVideo] = useState(video)
  const [srcPoster, setSrcPoster] = useState(poster)
  const [isPlaying, setIsPlaying] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 1279px)' })
  const [play, { stop, pause }] = useSound(sound, {
    onplay: () => setIsPlaying(true),
    onend: () => setIsPlaying(false),
    volume: 0.7,
  })

  function setSrc(srcV, srcP) {
    setSrcVideo(srcV)
    setSrcPoster(srcP)
  }

  useEffect(() => {
    difficulty.value === 'daltonizm' ? setSrc(videoD, posterD) : setSrc(video, poster)
    difficulty.value === 'visual' ? play() : stop()
  }, [difficulty, play, stop])
  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])
  return (
    <div className="main-wrap main-wrap__white">
      {isMobile ? (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={''}
          setKey={setKey}
          isModalStory={true}
        />
      ) : (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={'about'}
          setKey={setKey}
          isModalStory={true}
        />
      )}
      <div className="content">
        <div className="container">
          <div className={styles.wrap}>
            <div className={styles.videoBlock}>
              <video
                autoPlay
                muted
                loop
                key={srcVideo}
                preload="true"
                poster={srcPoster}
                controls={false}
                playsInline>
                <source src={srcVideo} type="video/webm" />
              </video>
            </div>

            <div className={`dyslexia-text ${styles.text} text-position-2`}>
              <p>
                Я&nbsp;поднимаю глаза туда, где небо. Я&nbsp;знаю, что оно бесконечно и&nbsp;не
                ограничено рамками окон. И&nbsp;на&nbsp;мгновение мне кажется, что я&nbsp;тоже могу
                быть такой&nbsp;же бесконечной и&nbsp;свободной.
              </p>
            </div>
          </div>
        </div>
      </div>
      {difficulty.value === 'visual' && (
        <Visual
          isPlaying={isPlaying}
          pause={pause}
          play={play}
          setIsPlaying={setIsPlaying}
          sound={<Sound />}
        />
      )}

      <Footer
        linkLeft={{ text: 'Назад', link: '/screen-6' }}
        linkRight={{ text: 'Дальше', link: '/about' }}
        currentStep={7}
        maxStep={7}
      />
    </div>
  )
}

export default Screen7
