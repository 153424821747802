import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import useSound from 'use-sound'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import styles from './Screen5.module.scss'
import sound from '../../assets/sounds/sound-screen-5.MP3'
import { ReactComponent as Sound } from '../../assets/images/sound.svg'
import { ReactComponent as OrageBorder } from '../../assets/images/orange-border.svg'
import posterLeft from '../../assets/video/page-5/Page_5_Pencil.gif'
import posterRight from '../../assets/video/page-5/Page_5_Teapot.gif'
import posterLeft_a from '../../assets/video/page-5/daltonizm/Page_5_a_Pencil.gif'
import posterRight_a from '../../assets/video/page-5/daltonizm/Page_5_a_Teapot.gif'
import videoLeft from '../../assets/video/page-5/Page-5_Pencil.webm'
import videoRight from '../../assets/video/page-5/Page-5_Teapot.webm'
import Visual from '../../components/Visual/Visual'
import useIsIphone from '../../utils/useIsIphone'
import Video from '../../components/Video/Video'

const Screen5 = ({ difficulty, setDifficulty, setKey }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [srcPoster, setSrcPoster] = useState({
    poster1: posterLeft,
    poster2: posterRight,
  })
  const isMobile = useMediaQuery({ query: '(max-width: 1279px)' })
  const [play, { stop, pause }] = useSound(sound, {
    onplay: () => setIsPlaying(true),
    onend: () => setIsPlaying(false),
    volume: 0.7,
  })

  const isIphone = useIsIphone()

  useEffect(() => {
    difficulty.value === 'daltonizm'
      ? setSrcPoster({
          poster1: posterLeft_a,
          poster2: posterRight_a,
        })
      : setSrcPoster({
          poster1: posterLeft,
          poster2: posterRight,
        })

    difficulty.value === 'visual' ? play() : stop()
  }, [difficulty, play, stop])
  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])
  return (
    <div className="main-wrap main-wrap__white">
      {isMobile ? (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={''}
          isModalStory={true}
          setKey={setKey}
        />
      ) : (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={'about'}
          isModalStory={true}
          setKey={setKey}
        />
      )}
      <div className="content">
        <div className="container">
          <div className={styles.wrap}>
            <div className={`dyslexia-text ${styles.text} text-position-2`}>
              <p>
                Звуки моего прошлого и&nbsp;будущего всегда со&nbsp;мной. Никто не&nbsp;поймёт,
                <br /> через&nbsp;что&nbsp;я&nbsp;прохожу.
                <br />
                <span className={`${styles.orangeBorder} orange-border daltonizm-color`}>
                  Всем все равно, <OrageBorder />
                </span>{' '}
                ведь это не&nbsp;их&nbsp;проблема. Мама учила меня не&nbsp;выносить сор
                из&nbsp;избы, притворяться, что все в&nbsp;порядке.
              </p>

              {isIphone ? (
                <>
                  <div className={styles.img + ' ' + styles.img__left_1}>
                    <img src={srcPoster.poster1} alt="Очень важная история" />
                  </div>
                  <div className={styles.img + ' ' + styles.img__right_1}>
                    <img src={srcPoster.poster2} alt="Очень важная история" />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.img + ' ' + styles.img__left_1}>
                    <Video key={videoLeft} srcPoster={posterLeft} srcVideo={videoLeft} />
                  </div>
                  <div className={styles.img + ' ' + styles.img__right_1 + ' ' + 'daltonizm-color'}>
                    <Video key={videoRight} srcPoster={posterRight} srcVideo={videoRight} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {difficulty.value === 'visual' && (
        <Visual
          isPlaying={isPlaying}
          pause={pause}
          play={play}
          setIsPlaying={setIsPlaying}
          sound={<Sound />}
        />
      )}
      <Footer
        linkLeft={{ text: 'Назад', link: '/screen-4' }}
        linkRight={{ text: 'Дальше', link: '/screen-6' }}
        currentStep={5}
        maxStep={7}
      />
    </div>
  )
}

export default Screen5
