import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import useSound from 'use-sound'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import styles from './Screen3.module.scss'
import video from '../../assets/video/Page-3.webm'
import videoD from '../../assets/video/Page-3a.webm'
import poster from '../../assets/video/Page_3.gif'
import posterD from '../../assets/video/Page_3_a.gif'
import sound from '../../assets/sounds/sound-screen-3.MP3'
import { ReactComponent as Sound } from '../../assets/images/sound.svg'
import Visual from '../../components/Visual/Visual'
import useIsIphone from '../../utils/useIsIphone'
import Video from '../../components/Video/Video'

const Screen3 = ({ difficulty, setDifficulty, setKey }) => {
  const [srcVideo, setSrcVideo] = useState(video)
  const [srcPoster, setSrcPoster] = useState(poster)
  const [isPlaying, setIsPlaying] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 1279px)' })
  const [play, { stop, pause }] = useSound(sound, {
    onplay: () => setIsPlaying(true),
    onend: () => setIsPlaying(false),
    volume: 0.5,
  })

  const isIphone = useIsIphone()

  function setSrc(srcV, srcP) {
    setSrcVideo(srcV)
    setSrcPoster(srcP)
  }

  useEffect(() => {
    difficulty.value === 'daltonizm' ? setSrc(videoD, posterD) : setSrc(video, poster)
    difficulty.value === 'visual' ? play() : stop()
  }, [difficulty, play, stop])
  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])
  return (
    <div className="main-wrap main-wrap__dark">
      {isMobile ? (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={''}
          isModalStory={true}
          setKey={setKey}
        />
      ) : (
        <Header
          showDifficulty={true}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          link={'about'}
          isModalStory={true}
          setKey={setKey}
        />
      )}
      <div className="content">
        <div className="container">
          <div className={styles.wrap}>
            <div className={styles.videoBlock}>
              {isIphone ? (
                <img src={srcPoster} alt="Очень важная история" />
              ) : (
                <Video key={srcVideo} srcPoster={srcPoster} srcVideo={srcVideo} />
              )}
            </div>
            <div className={`dyslexia-text ${styles.text} text-position-1`}>
              <p>
                Я&nbsp;слышу вопли панелек. Нет, это не&nbsp;гул ветра и&nbsp;не&nbsp;скрип ржавых
                подъездных дверей. Вопят не&nbsp;они сами, вопят&nbsp;те, кто внутри. Особенно
                летом. Летом я&nbsp;чувствую, как все цветёт, горячее солнце дотрагивается
                до&nbsp;моей кожи, и&nbsp;я&nbsp;тоже как будто живу.
              </p>
              <p>
                И&nbsp;я&nbsp;ощущаю, как узникам панельных коробок тесно и&nbsp;жарко. Они отворяют
                окна, раскрывают форточки, выползают на&nbsp;балконы. И&nbsp;тогда я&nbsp;слышу, как
                они умеют кричать. Смачно браниться, визжать и&nbsp;рыдать.
              </p>
            </div>
          </div>
        </div>
      </div>
      {difficulty.value === 'visual' && (
        <Visual
          isPlaying={isPlaying}
          pause={pause}
          play={play}
          setIsPlaying={setIsPlaying}
          sound={<Sound />}
        />
      )}
      <Footer
        linkLeft={{ text: 'Назад', link: '/screen-2' }}
        linkRight={{ text: 'Дальше', link: '/screen-4' }}
        currentStep={3}
        maxStep={7}
      />
    </div>
  )
}

export default Screen3
